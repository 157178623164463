<template>
  <transition name="slide">
    <div class="subpage">
      <PageText>
        <template v-slot:title>{{ $route.meta.title }}</template>
        <template v-slot:subtitle v-if="$route.meta.subtitle">{{ $route.meta.subtitle }}</template>
        <template v-slot:text>
          <p class="page-text__text">„Niech żyje Chrystus Król i Matka Boża z Guadalupe!”</p>
          <p class="page-text__text">
            Jose Sancheza del Rio można opisać jako młodego chłopaka po uszy zakochanego w Chrystusie. Już w wieku 14 lat był
            gotowy oddać za niego życie.
          </p>
          <p class="page-text__text">
            Joselito urodził się w Sahuyao w Meksyku, 28 marca 1913 roku. Gdy miał cztery lata uchwalono nową konstytucję,
            która ograniczała przywileje Kościoła Katolickiego. Wkrótce zakazano procesji religijnych odprawiania mszy na
            zewnątrz oraz wszelkiego publicznego kultu.
          </p>
          <p class="page-text__text">
            Joselito wychowywał się w miłości do codziennej Eucharystii. Odkąd nauczył się ministrantury codziennie
            uczestniczył we Mszy Św. i modlił się modlitwą różańcową. Gdy w 1926 roku prześladowania władz Meksyku wobec
            kościoła nasiliły się, postanowił podążyć śladami swoich braci i dołączyć do ruchu Christeros - partyzantów
            stających w obronie swojej wiary.
          </p>
          <p class="page-text__text">
            Idąc do oddziałów Christeros wiedział, że grozi mu śmierć. Matka próbowała przekonać go do porzucenia zamiaru
            dołączenia do powstańców. Odpowiedział jej jednak: „Muszę iść, nie będę miał lepszej okazji żeby dostać się do
            Nieba niż teraz. Nie chcę jej stracić.” Powstanie Christero wspierał zajmując się głównie końmi walczących.
            Przygotowywał tez posiłki i sprzęt. Powierzono mu niesienie sztandaru.
          </p>
          <p class="page-text__text">
            Kiedy podczas potyczki z wojskami federalnymi 25 stycznia 1928 r. dowódca oddziału Christeros stracił swojego
            konia, Jose ubłagał go by ten przyjął jego konia i ratował się ucieczką. Joselito wiedział, że może się to wiązać
            z jego rychłym aresztowaniem. Stało się to w trakcie odwrotu oddziału.
          </p>
          <p class="page-text__text">
            Został schwytany i przewieziony Sahuayo, gdzie więziono go i przesłuchiwano przez wiele dni. Wojska federalne
            próbowały zmusić go do porzucenia wiary i wyrzeczenia się Chrystusa. Nie uczynił tego. Wkrótce został skazany na
            śmierć. Zmarł śmiercią męczeńską 10 lutego 1928 roku.
          </p>
          <p class="page-text__text">
            Na kilka dni przed śmiercią napisał do swojej matki: „Droga Mamo, dostałem się dziś do niewoli. Wierzę, że
            wkrótce umrę. Ale to nie ma dla mnie znaczenia. Pogódź się z wolą Bożą. Będę umierał szczęśliwy, ponieważ zginę w
            szeregach żołnierzy Boga. Nie zamartwiaj się moją śmiercią”.
          </p>
          <p class="page-text__text">Postać Jose przybliża film „ Christiada” z 2012 roku.</p>
          <div class="btn" style="display: flex">
            <router-link to="/patroni">
              <Btn text="Powrót" :reverse="true" />
            </router-link>
          </div>
        </template>
      </PageText>
    </div>
  </transition>
</template>

<script>
export default {
  components: {},
};
</script>
<style lang="scss" scoped>
</style>
